<template>
  <v-card class="card-comments" flat>
    <v-toolbar color="primary" dark flat>
      <!-- avec vuetify -->
      <v-toolbar-title>
        <v-icon left>mdi-comment-text-multiple</v-icon>
        Commentaires
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <!-- <div class="d-flex align-center">
        <span>Commentaires</span>
      </div>
      <v-btn icon small @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn> -->
    </v-toolbar>

    <v-card-text class="px-4 pt-2 pb-0">
      <div class="chat-container">
        <!-- Description principale -->
        <div class="chat-message left-message">
          <v-avatar color="grey" size="36" class="mr-3">
            <span class="white--text subtitle-2">{{
              getInitials(cardData.carte.userCreate)
            }}</span>
          </v-avatar>
          <div class="message-content">
            <div class="message-header">
              <span class="font-weight-medium">{{ cardData.carte.userCreate }}</span>
              <span class="caption grey--text ml-2">{{
                formatDate(cardData.createdAt)
              }}</span>
            </div>
            <div class="message-body" v-html="cardData.carte.description"></div>
            <div
              v-if="cardData.carte.images && cardData.carte.images.length"
              class="mt-2"
            >
              <v-chip
                v-for="file in cardData.carte.images"
                :key="file.filename"
                class="mr-2 mb-2"
                small
                @click="previewAttachment(file)"
              >
                <v-icon left small>{{ getFileIcon(file.name) }}</v-icon>
                {{ file.name }}
              </v-chip>
            </div>
          </div>
        </div>

        <!-- Commentaires additionnels -->
        <div
          v-for="(comment, index) in cardData.carte.commentaires"
          :key="index"
          :class="[
            'chat-message',
            comment.author === currentUser ? 'right-message' : 'left-message',
          ]"
        >
          <template v-if="comment.author !== currentUser">
            <v-avatar color="secondary" size="36" class="mr-3">
              <span class="white--text subtitle-2">{{
                getInitials(comment.author)
              }}</span>
            </v-avatar>
          </template>
          <div class="message-content">
            <div class="message-header">
              <span class="font-weight-medium">{{ comment.author }}</span>
              <span class="caption grey--text ml-2">{{ formatDate(comment.date) }}</span>
            </div>
            <div class="message-body" v-html="comment.comment"></div>
            <div v-if="comment.images && comment.images.length" class="mt-2">
              <v-chip
                v-for="file in comment.images"
                :key="file.filename"
                class="mr-2 mb-2"
                small
                @click="previewAttachment(file)"
              >
                <v-icon left small>{{ getFileIcon(file.name) }}</v-icon>
                {{ file.name }}
              </v-chip>
            </div>
          </div>
          <template v-if="comment.author === currentUser">
            <v-avatar color="#3b5998" size="36" class="ml-3">
              <span class="white--text subtitle-2">{{
                getInitials(comment.author)
              }}</span>
            </v-avatar>
          </template>
        </div>
      </div>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="pt-2 px-4 pb-4">
      <v-avatar color="primary" size="36" class="mr-3">
        <span class="white--text subtitle-2">{{ getInitials(currentUser) }}</span>
      </v-avatar>
      <div style="width: 100%" class="d-flex align-center justify-space-between">
        <editor
          v-model="newComment"
          placeholder="Ajouter un commentaire..."
          @select-attachment="dialogPieceJointe = true"
          class="comment-input"
        ></editor>
        <div class="d-flex align-center justify-center">
          <v-btn
            color="primary"
            @click="addComment"
            :disabled="!newComment.trim() || isAddingComment"
            :loading="isAddingComment"
          >
            <v-icon left>mdi-send</v-icon>
            Envoyer
          </v-btn>
        </div>
      </div>
    </v-card-actions>

    <v-card-text v-if="selectedFiles.length > 0" class="px-4 pt-0">
      <v-chip
        v-for="(file, index) in selectedFiles"
        :key="index"
        class="mr-2 mb-2"
        close
        @click:close="removeSelectedFile(index)"
      >
        <v-icon left small>{{ getFileIcon(file.name) }}</v-icon>
        {{ file.name }}
      </v-chip>
    </v-card-text>

    <v-dialog v-model="dialogPieceJointe" max-width="500px">
      <v-card>
        <v-card-title class="headline">Ajouter une pièce jointe</v-card-title>
        <v-card-text>
          <v-file-input
            v-model="newSelectedFiles"
            label="Choisir un fichier"
            @change="handleFilesChange"
            multiple
            accept="image/*, application/pdf"
            show-size
            counter
            clearable
          ></v-file-input>
          <!-- Afficher l'aperçu de tous les fichiers (précédemment sélectionnés et nouveaux) -->
          <v-row v-if="allSelectedFiles.length > 0" class="mt-4">
            <v-col v-for="(file, index) in allSelectedFiles" :key="index" cols="6">
              <v-card class="text-center">
                <v-card-text>
                  <v-img
                    v-if="isImage(file.name)"
                    :src="getFilePreviewUrl(file)"
                    contain
                    max-height="100"
                  ></v-img>
                  <v-icon v-else-if="isPdf(file.name)" color="red"
                    >mdi-file-pdf-box</v-icon
                  >
                  <p v-else>{{ file.name }}</p>
                </v-card-text>
                <v-card-actions>
                  <v-btn small text color="error" @click="removeSelectedFile(index)"
                    >Supprimer</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelAttachment">Annuler</v-btn>
          <v-btn color="primary" @click="addAttachment">Ajouter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewDialog" max-width="40%">
      <v-card>
        <v-card-title class="headline">
          {{ previewAttachmentName }}
          <v-spacer></v-spacer>
          <v-btn icon @click="previewDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center">
          <v-img
            v-if="isImage(previewAttachmentName)"
            :src="previewAttachmentUrl"
            contain
            max-height="500"
          ></v-img>
          <iframe
            v-else-if="isPdf(previewAttachmentName)"
            :src="previewAttachmentUrl"
            width="100%"
            height="500px"
          ></iframe>
          <p v-else>Impossible d'afficher l'aperçu pour ce type de fichier.</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DeveloppementService from "@/Services/SupportVision/DeveloppementService";
import Editor from "@/Components/Commons/Inputs/Editor/Editor.vue";

export default {
  name: "CardComments",
  components: {
    Editor,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    location: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cardData: JSON.parse(JSON.stringify(this.card)), // Copie profonde de la carte
      newComment: "",
      dialogPieceJointe: false,
      selectedFiles: [], // Fichiers sélectionnés et confirmés
      newSelectedFiles: [], // Nouveaux fichiers sélectionnés dans la modale
      previewDialog: false,
      previewAttachmentUrl: null,
      previewAttachmentName: "",
      isAddingComment: false,
    };
  },
  computed: {
    ...mapState(["informations"]),
    currentUser() {
      return this.informations.firstname + " " + this.informations.lastname;
    },
    allSelectedFiles() {
      return [...this.selectedFiles, ...this.newSelectedFiles];
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    getInitials(name) {
      return name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase();
    },
    async addComment() {
      if (this.newComment.trim()) {
        this.isAddingComment = true;
        try {
          const newCommentData = {
            comment: this.newComment,
            author: this.currentUser,
            date: new Date(),
            images: this.selectedFiles.map((file) => ({
              name: file.name,
              mimetype: file.type,
              filename: file.name,
              url: file.url, // URL locale pour l'aperçu
            })),
          };

          // Mise à jour locale immédiate
          if (!this.cardData.carte.commentaires) {
            this.cardData.carte.commentaires = [];
          }
          this.cardData.carte.commentaires.push(newCommentData);

          const formData = new FormData();
          formData.append("comment", this.newComment);
          formData.append("author", this.currentUser);
          this.selectedFiles.forEach((file) => formData.append("file", file));

          // Envoi au serveur
          const response = await DeveloppementService.editDev(
            this.cardData._id,
            formData
          );

          // Mise à jour avec les données du serveur si nécessaire
          this.cardData = response.data;

          this.$emit("comment-added", this.cardData);
          this.newComment = "";
          this.selectedFiles = [];
        } catch (error) {
          console.error("Erreur lors de l'ajout du commentaire:", error);
          this.$nError("Erreur lors de l'ajout du commentaire");
          // Annuler la mise à jour locale en cas d'erreur
          this.cardData.carte.commentaires.pop();
        } finally {
          this.isAddingComment = false;
        }
      }
    },
    handleFilesChange(files) {
      this.newSelectedFiles = files;
    },
    addAttachment() {
      // Ajouter les nouveaux fichiers sélectionnés à la liste des fichiers sélectionnés
      this.selectedFiles = [...this.selectedFiles, ...this.newSelectedFiles];
      this.newSelectedFiles = []; // Réinitialiser les nouveaux fichiers sélectionnés
      this.dialogPieceJointe = false;
    },
    cancelAttachment() {
      this.newSelectedFiles = []; // Annuler la sélection des nouveaux fichiers
      this.dialogPieceJointe = false;
    },
    removeSelectedFile(index) {
      if (index < this.selectedFiles.length) {
        // Si le fichier est dans selectedFiles
        this.selectedFiles.splice(index, 1);
      } else {
        // Si le fichier est dans newSelectedFiles
        this.newSelectedFiles.splice(index - this.selectedFiles.length, 1);
      }
    },
    previewAttachment(file) {
      if (file.url) {
        // Utiliser l'URL locale si disponible (pour les nouveaux fichiers)
        this.previewAttachmentUrl = file.url;
      } else {
        // Utiliser l'URL basée sur this.location pour les fichiers existants
        this.previewAttachmentUrl = `${this.location}/read-file?id=${file.filename}&mime=${file.mimetype}`;
      }
      this.previewAttachmentName = file.name;
      this.previewDialog = true;
    },
    getFileIcon(fileName) {
      if (this.isImage(fileName)) return "mdi-file-image";
      if (this.isPdf(fileName)) return "mdi-file-pdf-box";
      return "mdi-file-document-outline";
    },
    isImage(fileName) {
      return /\.(jpg|jpeg|png|gif)$/i.test(fileName);
    },
    isPdf(fileName) {
      return /\.pdf$/i.test(fileName);
    },
    getFilePreviewUrl(file) {
      return file instanceof File ? URL.createObjectURL(file) : file.url;
    },
  },
  watch: {
    card: {
      handler(newCard) {
        this.cardData = JSON.parse(JSON.stringify(newCard));
      },
      deep: true,
    },
  },
  beforeDestroy() {
    // Nettoyer les URL locales créées
    [...this.selectedFiles, ...this.newSelectedFiles].forEach((file) => {
      if (file instanceof File) {
        URL.revokeObjectURL(file);
      } else if (file.url) {
        URL.revokeObjectURL(file.url);
      }
    });
  },
};
</script>

<style scoped>
.card-comments {
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.chat-container {
  max-height: 400px;
  overflow-y: auto;
  padding: 16px 0;
}

.chat-message {
  display: flex;
  margin-bottom: 16px;
  position: relative;
}

.left-message {
  justify-content: flex-start;
}
.left-message .message-content {
  background-color: #ede7f6;
  color: #5e35b1;
}
.left-message .message-content::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #ede7f6;
}

.right-message {
  justify-content: flex-end;
}
.right-message .message-content {
  background-color: #3b5998;
  color: #ffffff;
}

.right-message .message-content::before {
  content: "";
  position: absolute;
  top: 10px;
  right: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #3b5998;
}

.message-content {
  max-width: 70%;
  padding: 8px 12px;
  border-radius: 12px;
  background-color: #f5f5f5;
  position: relative;
}

.message-header {
  margin-bottom: 4px;
}

.message-body {
  word-break: break-word;
}

.comment-input {
  flex-grow: 1;
  margin-right: 16px;
}

/* Personnalisation de la barre de défilement */
.chat-container::-webkit-scrollbar {
  width: 8px;
}

.chat-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chat-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.chat-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Styles pour les pièces jointes */
.v-chip {
  cursor: pointer;
  transition: background-color 0.3s;
}

.v-chip:hover {
  background-color: #e0e0e0;
}

/* Animation pour l'apparition des messages */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chat-message {
  animation: fadeIn 0.3s ease-out;
}

/* Responsive design */
@media (max-width: 600px) {
  .message-content {
    max-width: 85%;
  }

  .v-card-actions {
    flex-direction: column;
    align-items: stretch;
  }

  .comment-input {
    margin-right: 0;
    margin-bottom: 16px;
  }
}
</style>
